import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from "gatsby-image";

const PageOverview = ({ page }) => {
    const { title, featuredImage, slug } = page;
    const defaultPageImage = useStaticQuery(graphql`
        query defaultPageImage {
            wintechFile: file(relativePath: { eq: "default-post-image.jpg" }) {
                childImageSharp {
                    thumbnail: fixed(width: 347, height: 215) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
            }
            kingFile: file(relativePath: { eq: "default-post-image-king.jpg" }) {
                childImageSharp {
                    thumbnail: fixed(width: 347, height: 215) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    let defaultPostImageSrc = defaultPageImage.wintechFile;
    let defaultAltText = 'WinTech Racing logo';
    if(process.env.GATSBY_THEME == "King") {
       defaultPostImageSrc = defaultPageImage.kingFile; 
       defaultAltText = 'King Racing logo';
    }

     // Sets the page image to the image supplied else uses a default page image
     const pageImage = featuredImage ? featuredImage.node.imageFile.childImageSharp.thumbnail : defaultPostImageSrc.childImageSharp.thumbnail;
     const pageAltTag = featuredImage ? featuredImage.altText : defaultAltText;

    return (
        <Link className="c-latest" to={`/${slug}/`} aria-label={`View full ${title} article`}>
            <article className="c-latest__inner">
                <Img className="c-latest__image" fixed={pageImage} alt={pageAltTag} />
                <h1 className="c-latest__title" dangerouslySetInnerHTML={{ __html: title }}></h1>
            </article>
        </Link>
    );
};

export default PageOverview;