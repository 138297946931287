import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import lunr from 'lunr';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import SearchHeader from '../components/search/component.search-header';
import SectionNav from '../components/component.SectionNav';
import ProductsList from '../components/plp/component.products-list';
import PostOverview from '../components/component.post-overview';
import PageOverview from '../components/component.page-overview';

const Search = ({ data, location }) => {
    const [query, setQuery] = useState('');
    const [postIndex, setPostIndex] = useState(null);
    const [pageIndex, setPageIndex] = useState(null);
    const [productIndex, setProductIndex] = useState(null);
    const [searchType, setSearchType] = useState('All');

    // Get content
    const products = data.wordpress.products.nodes;
    const pages = data.wordpress.pages.nodes;
    const posts = data.wordpress.posts.nodes;

    // Containers to show results
    let filteredPosts = [];
    let filteredPages = [];
    let filteredProducts = [];

    // Default settings
    const title = `Search ${searchType}`;
    const maxResults = 10;
    const minCharacters = 3;

    const sectionLinks = [
        {
            name: 'All',
            onClick: function () {
                setSearchType('All');
            },
        },
        {
            name: 'Products',
            onClick: function () {
                setSearchType('Products');
            },
        },
        {
            name: 'Pages',
            onClick: function () {
                setSearchType('Pages');
            },
        },
        {
            name: 'Posts',
            onClick: function () {
                setSearchType('Posts');
            },
        },
    ];

    /**
     * Creates Lunr index for given data
     *
     * @param {*} { data, ref, fields = [] }
     * @returns
     */
    function getIndex({ data, ref, fields = [] }) {
        return lunr(function () {
            this.ref(ref);
            fields.map(field => {
                this.field(field)
            });

            data.map(item => this.add(item));
        });
    }

    // Component did mount
    useEffect(() => {
        // Set inital query
        const initalQuery = location.state ? location.state.searchQuery : '';
        setQuery(initalQuery);

        // Setup the search index on load
        const postIndex = getIndex({
            data: posts,
            ref: 'id',
            fields: ['title', 'content'],
        });
        const pageIndex = getIndex({
            data: pages,
            ref: 'id',
            fields: ['title', 'content'],
        });
        // raise any fields we want to search on to the top level, so that Lunr can access them:
        products.map(product => {
            product.shortDesc = product.ACFProductBlockFields.shortDescription;
        });
        const productIndex = getIndex({
            data: products,
            ref: 'id',
            fields: ['title', 'shortDesc'],
        });

        setPostIndex(postIndex);
        setPageIndex(pageIndex);
        setProductIndex(productIndex);
    }, []);

    // Updates if the search query is changed in the header search
    useEffect(() => {
        const updatedQuery = location.state ? location.state.searchQuery : '';
        setQuery(updatedQuery);
    }, [location]);

    // When query is updated
    if (query && query.length >= minCharacters) {
        if ((searchType === 'All' || searchType === 'Posts') && postIndex && query) {
            const postResults = postIndex.search(`${query}~1`).slice(0, maxResults);
            filteredPosts = findContent({
                type: 'post',
                results: postResults,
            });
        }

        if ((searchType === 'All' || searchType === 'Pages') && pageIndex && query) {
            const pageResults = pageIndex.search(`${query}~1`).slice(0, maxResults);
            filteredPages = findContent({
                type: 'page',
                results: pageResults,
            });
        }

        if ((searchType === 'All' || searchType === 'Products') && productIndex && query) {
            const productResults = productIndex.search(`${query}~1`).slice(0, maxResults);
            filteredProducts = findContent({
                type: 'product',
                results: productResults,
            });
        }
    }

    /**
     * Filters content (post, page or products) that match the search
     *
     * @returns
     */
    function findContent({ results, type }) {
        const filteredContent = [];

        results.map(result => {
            if (type === 'post') {
                if (results && (searchType === 'All' || searchType === 'Posts')) {
                    const post = posts.filter(post => post.id === result.ref)[0];

                    if (post) {
                        filteredContent.push(post);
                    }
                }
            }

            if (type === 'page') {
                if (results && (searchType === 'All' || searchType === 'Pages')) {
                    const page = pages.filter(page => page.id === result.ref)[0];

                    if (page) {
                        filteredContent.push(page);
                    }
                }
            }

            if (type === 'product') {
                if (results && (searchType === 'All' || searchType === 'Products')) {
                    const product = products.filter(product => product.id === result.ref)[0];

                    if (product) {
                        filteredContent.push(product);
                    }
                }
            }
        });

        return filteredContent;
    }

    function handleSubmit(e) {
        e.preventDefault();
        setQuery(query);
    }

    return (
        <Layout>
            <SEO title={title} />
            <SearchHeader title={title} searchCallback={handleSubmit} queryUpdate={setQuery} query={query} />
            <SectionNav title={title} links={sectionLinks} active={searchType} />

            <div className="xs-pb-30 lg-pt-40 lg-pb-40">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            {/* PRODUCTS */}
                            {filteredProducts.length && (searchType === 'All' || searchType === 'Products') ?
                                <section className="xs-mb-20 md-mb-30">
                                    <h2>Products</h2>
                                    <ProductsList products={filteredProducts} />
                                </section>
                            : null}

                            {!filteredProducts.length && (searchType === 'All' || searchType === 'Products') ?
                                <section className="xs-mb-20 md-mb-30">
                                    <h2>Products</h2>
                                    <div className="row">
                                        <div className="col">
                                            <p>There are no products matching your search.</p>
                                        </div>
                                    </div>
                                </section>
                            : null}

                            {/* PAGES */}
                            {filteredPages.length && (searchType === 'All' || searchType === 'Pages') ?
                                <section className="xs-mb-20 md-mb-30">
                                    <h2>Pages</h2>
                                    <div className="row">
                                        {filteredPages.map((page, index) => (
                                            <div key={index} className="col-md-4">
                                                <PageOverview page={page} />
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            : null}

                            {!filteredPages.length && (searchType === 'All' || searchType === 'Pages') ?
                                <section className="xs-mb-20 md-mb-30">
                                    <h2>Pages</h2>
                                    <div className="row">
                                        <div className="col">
                                            <p>There are no pages matching your search.</p>
                                        </div>
                                    </div>
                                </section>
                            : null}

                            {/* POSTS */}
                            {filteredPosts.length && (searchType === 'All' || searchType === 'Posts') ?
                                <section className="xs-mb-20 md-mb-30">
                                    <h2>Posts</h2>
                                    <div className="row">
                                        {filteredPosts.map((post, index) => (
                                            <div key={index} className="col-md-4">
                                                <PostOverview post={post} />
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            : null}

                            {!filteredPosts.length && (searchType === 'All' || searchType === 'Posts') ?
                                <section className="xs-mb-20 md-mb-30">
                                    <h2>Posts</h2>
                                    <div className="row">
                                        <div className="col">
                                            <p>There are no posts matching your search.</p>
                                        </div>
                                    </div>
                                </section>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query Search {
        wordpress {
            posts(first: 10000, where: {orderby: {field: DATE, order: DESC}, status: PUBLISH}) {
                nodes {
                    id
                    title
                    content
                    slug
                    date
                    featuredImage {
                        node {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    thumbnail: fixed(width: 347, height: 215) {
                                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    categories(first: 1) {
                        nodes {
                            name
                            slug
                        }
                    }
                }
            }
            products(first: 10000) {
                nodes {
                    id
                    slug
                    title
                    ACFProductBlockFields {
                        productType
                        classesInformation {
                            class {
                                ... on WORDPRESS_Class {
                                    name
                                }
                            }
                        }
                        brandLogo {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fixed(width: 40) {
                                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                        whoShouldBuyIt
                        price
                        skill
                        shortDescription
                        bestSeller
                        racesWon {
                            race
                        }
                        mainImage {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 1580) {
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    productCategories {
                        nodes {
                            name
                        }
                    }
                    classes {
                        nodes {
                            name
                            id
                        }
                    }
                    levels {
                        nodes {
                            id
                            name
                        }
                    }
                }
            }
            pages(first: 10000) {
                nodes {
                    id
                    title
                    content
                    slug
                    featuredImage {
                        node {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    thumbnail: fixed(width: 347, height: 215) {
                                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default Search;
