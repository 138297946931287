import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const SearchHeader = ({ title, searchCallback, queryUpdate, query }) => {
    const imageData = useStaticQuery(graphql`
        query {
            wintechFile: file(relativePath: { eq: "default-banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
            kingFile: file(relativePath: { eq: "default-banner-king.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    let defaultPostImageSrc = imageData.wintechFile;
    if(process.env.GATSBY_THEME == "King") {
        defaultPostImageSrc = imageData.kingFile;
    }

    return (
        <BackgroundImage
          Tag="section"
          className="c-hero-input"
          fluid={defaultPostImageSrc.childImageSharp.fluid}
        >
            <div className="c-hero-input__caption">
                <h1 className="c-hero-input__title">{title}</h1>

                <form className="c-hero-input__search" onSubmit={(e) => searchCallback(e)}>
                    <input 
                        name='query'
                        className="c-hero-input__search-input"
                        placeholder="Search products, posts and pages"
                        onChange={e => queryUpdate(e.target.value)}
                        value={query}
                    />
                    <button className="c-hero-input__search-button" aria-label="search">
                        <i className="c-hero-input__search-icon far fa-search"></i>
                    </button>
                </form>
            </div>
        </BackgroundImage>
    );
};

export default SearchHeader;