import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';

const SectionNav = ({ title = '', links, active, slugPrefix, partiallyActive }) => {
    const [showCategories, setShowCategories] = useState(false);
    const sectionId = `sectionNav${title.replace(/\s/g, '')}`;
    const navEl = useRef(null);

    // If navigation is open add a listner for external clicks to trigger close
    useEffect(() => {
        if (showCategories) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showCategories]);

    const handleClickOutside = (e) => {
        if (navEl.current.contains(e.target)) {
            // Click inside dropdown
            return false;
        }

        setShowCategories(false);
    };

    return (
        <div ref={navEl} className="c-section-nav u-bg--pickled-bluewood">
            <div
                className={showCategories ? "c-section-nav__inner c-section-nav__inner--active" : "c-section-nav__inner"}
                onClick={() => setShowCategories(!showCategories)}
                role="navigation"
            >
                <div className="container">
                    <span className="c-section-nav__title d-lg-none d-xl-none" id={sectionId}>{title}</span>
                    <ul className="c-section-nav__list" aria-labelledby={sectionId}>
                        {links.map((link, index) => {
                            let linkName = link.name;
                            if(process.env.GATSBY_THEME == "King") { 
                                if(linkName == "Racing") {
                                    linkName = "Boats";
                                }
                            }
                            if (link.slug) {
                                return (
                                    <li key={index} className="c-section-nav__item">
                                        <Link
                                            className="c-section-nav__link"
                                            activeClassName="c-section-nav__link--active"
                                            to={slugPrefix ? `/${slugPrefix}/${link.slug}/` : `/${link.slug}/`}
                                            partiallyActive={partiallyActive}
                                        >
                                            <span className="c-section-nav__link-name" dangerouslySetInnerHTML={{ __html: linkName }}></span>
                                        </Link>
                                    </li>
                                );
                            } else if (link.onClick) {
                                return (
                                    <li key={index} className="c-section-nav__item">
                                        <button
                                            className={active === link.name ? "c-section-nav__link c-section-nav__link--active" : "c-section-nav__link"}
                                            onClick={link.onClick}
                                        >
                                            <span className="c-section-nav__link-name">{linkName}</span>
                                        </button>
                                    </li>
                                );
                            }
                            return false;
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SectionNav;
